import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class OtherCashesService {

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/otherCashes/list', {
        page: page,
        rowPerPage: rowPerPage,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {

        reject(err)
      })
    })
  }

  create(otherCash) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/otherCashes/create', otherCash).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }


  update(otherCash) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/otherCashes/update', { params: otherCash, id: otherCash.id }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  delete(otherCash) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!otherCash.id) {
        reject('id undefined')
      }
      axios.post(apiUrl + '/payments/otherCashes/delete', {
        id: otherCash.id
      }).then((response) => {
        if (!response.success) {
          reject(response.results)
        } else {
          resolve(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  get(otherCashId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/payments/otherCashes/get', { id: otherCashId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  fields() {
    return Promise.resolve([
      { text: 'Importo', value: 'amount', type: 'price' },
      { text: 'Descrizione', value: 'description' },
      { text: 'Codice', value: 'internalCode' },
      { text: 'Mittente', value: 'recipient', type: 'custom' },
      { text: 'Numero Documento', value: 'documentNumber' },
      { text: 'Emesso il', value: 'emittedAt', type: "datetime" },
      { text: 'Data Scadenza', value: 'expirationDate', type: "datetime" },
    ])
  }

  _fieldsToExport() {
    return {
      'Importo': {
        callback: (row) => {
          return Number(row.amount).toFixed(2).replace('.', ',')
        }
      },
      'Descrizione': 'description',
      'Codice': 'internalCode',
      'Numero Documento': 'documentNumber',
      'Emesso il': {
        callback: (row) => {
          if (!!row.emittedAt)
            return new Date(row.emittedAt).toLocaleDateString()
        }
      },
      'Data Scadenza': {
        callback: (row) => {
          if (!!row.expirationDate)
            return new Date(row.expirationDate).toLocaleDateString()
        }
      },
    }
  }
}

export default new OtherCashesService();
